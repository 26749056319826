
import { defineComponent, ref } from 'vue'

export default defineComponent({
  emits: ['reset'],
  props: {
    modalSize: { type: String, default: 'general' },
    // 內容排列
    contentAlign: { type: String, default: 'center' },
    // 按鈕排列
    btnAlign: { type: String, default: 'center' },
    // 背景關閉
    bgClose: { type: Boolean, default: true }
  },
  setup(props, { emit }) {
    const show = ref(false)

    const sizeMap = {
      general: '',
      lg: 'c-modal--lg',
      md: 'c-modal--md'
    }

    const alignClassMap = {
      left: 'u-textLeft',
      center: 'u-textCenter',
      right: 'u-textRight'
    }

    function open() {
      show.value = true
    }

    function close(container: string) {
      if (container === 'mask' && !props.bgClose) return false
      show.value = false
      reset()
    }

    function reset() {
      emit('reset')
    }

    return {
      show,
      open,
      close,
      reset,
      sizeMap,
      alignClassMap
    }
  }
})
