import axios, { AxiosRequestConfig } from 'axios'
import store from '../store'

const baseUrl = process.env.VUE_APP_AXIOS_BASEURL

const fileInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true
})

fileInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const userInfo = store.getters.userInfo

    if (userInfo && userInfo.token) {
      const token = userInfo.token
      if (token) {
        config.headers = {
          token: token
        }
      }
    }

    return config
  },
  (error) => {
    return Promise.resolve(error)
  }
)

export default fileInstance
